/**************************************************
 * Nombre:       PaginaConstruccion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Solicitud_Contrasena from "./Secciones/Solicitud_Contrasena";
import Pagina from "./Secciones/Pagina";

const PaginaConstruccion = () => {


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"

        >


            <Router>


                <Routes>


                    <Route path={"/Registro/:id"} element={<Solicitud_Contrasena/>}/>
                    <Route path={"/*"} element={<Pagina/>}/>


                </Routes>


            </Router>


        </Grid>
    )

}
export default PaginaConstruccion    