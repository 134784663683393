/**************************************************
 * Nombre:       Formulario_Incripcion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Apartment, Email, Flag, HomeWork, Password, Person, PhoneAndroid} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {PAISES} from "../../Constantes";

const Formulario_contrasena = ({...props}) => {

    return (
        <form noValidate>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Person} nombre={"Correo"} dato={'correo'} {...props}
                                  requerido={'El correo es requerido'}
                                  editable={false}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                    <IngresoTexto Icono={Password} nombre={"Contraseña"} dato={'pass'} {...props} type={'password'} />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Password} nombre={"Repite Contraseña"} dato={'passDos'} {...props} type={'password'} />
                </Grid>


            </Grid>


        </form>
    )

}
export default Formulario_contrasena