import {
    CreditScoreSharp, DashboardCustomizeOutlined,
    Facebook,
    Instagram,
    LocalOfferOutlined,
    LocalShippingOutlined,
    WhatsApp
} from "@mui/icons-material";

export const SECCIONES = ['Tiendas', 'Inscríbete']

export const ESTADOSPOTENCIALES = ['Nueva', 'Contactada', 'Aceptada', 'Archivada']
export const ESTADOSEMPRESARIAS = ['Nueva', 'Pendiente', 'Registrada']

export const ESTADOTIENDASREVISON = ['En revision', 'Ajustes pendientes']

export const ESTADOPRODUCTOS = ['Publicado', 'En revision', 'Ajustes pendientes']
export const TIPOPRODUCTOS = ['Listo', 'Bajo pedido']

export const TIPOMONEDA = ['Pesos Colombianos', 'Dolares']
export const REDES = [
    {
        nombre: 'facebook',
        icono: Facebook,
        url: 'https://www.facebook.com/mujeresempresariasunidas'
    },
    {
        nombre: 'instagram',
        icono: Instagram,
        url: 'https://www.instagram.com/mujeresempresariasunidas/'
    },
    {
        nombre: 'whatsapp',
        icono: WhatsApp,
        url: 'https://api.whatsapp.com/send?phone=573168202436&text=Hola%20necesito%20mas%20informaci%C3%B3n'
    },
]


export const PAISES = ['Colombia', 'Ecuador']


export const CARACTERISTICAS = [
    {
        nombre: 'Ofertas exclusivas',
        icono: LocalOfferOutlined,
        descripcion: 'Productos desde $10.000'
    },
    {
        nombre: 'Envíos nacionales',
        icono: LocalShippingOutlined,
        descripcion: '14 empresas de envío'
    },
    {
        nombre: 'Pagos seguros',
        icono: CreditScoreSharp,
        descripcion: 'Múltiples medios de pago'
    },
    {
        nombre: 'Múltiples categorías',
        icono: DashboardCustomizeOutlined,
        descripcion: 'Todo en 1 lugar'
    },
]