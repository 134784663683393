/**************************************************
 * Nombre:       Registro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Checkbox, Grid, Typography} from "@mui/material";
import {theme} from "../../Tema";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Formulario_contrasena from "../Formularios/Formulario_contrasena";
import {TaskAlt} from "@mui/icons-material";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {adaptador_entidad_tienda} from "../Adaptadores/adaptador_entidad_tienda";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {funCrearUsuario} from "../../Servicios/Auth/funCrearUsuario";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_color.svg'

const Registro = () => {
    const parms = useParams()
    const {props, setEntidad, obtenerEntidad} = useFormulario({valoresDefecto: {}})
    const [aceptado, setAceptado] = useState(false)
    const {abrirCargador, Cargador, cerrarCargador} = useLoaders({logo: logo})


    const crearCuenta = () => {

        if (!aceptado) {
            alert('Debes aceptar las politicas de tratamiento de datos antes de crear tu cuenta')
        }

        obtenerEntidad().then((entidad) => {

            if (comprobarPass(entidad)) {
                let obj = adaptador_entidad_tienda(entidad)
                abrirCargador('Creando tu cuenta, al finalizar ingresaras automaticamente')
                setTimeout(() => {
                    funCrearUsuario(obj.correo, entidad.pass).then((dox) => {
                        if (dox.res) {
                            guardarDoc('usuarios', obj).then((das) => {
                                if (das.res) {
                                    cerrarCargador()
                                }
                            })
                        } else {
                            alert(dox.data)
                            cerrarCargador()
                        }

                    })
                }, 1500)

            }
        })

    }

    const comprobarPass = (en) => {

        if (en.pass === en.passDos) {
            return true
        } else {
            alert('las contraseñas no coinciden')
            setEntidad({...en, pass: '', passDos: '',})
        }
    }

    useEffect(() => {
        if (parms && parms.id) {

            obtenerDoc('empresarias', parms.id).then((dox) => {

                if (dox.res) {
                    setEntidad(dox.data)
                }
            })

        }
    }, [parms]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{pb: 8}}
        >

            <Cargador/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={11} sm={8} xs={12} sx={{justifyContent: 'center', marginTop: 12}}>
                    <Typography sx={{
                        color: theme.palette.primary.main,
                        fontSize: 38,
                        fontWeight: 800,
                        textAlign: 'center',
                        lineHeight: 1.2
                    }}>Felicidades por
                        tu aprobación</Typography>
                </Grid>

                <Grid item container lg={3} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}/>

                <Grid item container lg={6} sm={8} xs={12} sx={{justifyContent: 'center', marginTop: 1}}>
                    <Typography sx={{fontSize: 18, fontWeight: 400, fontFamily: 'Lato', textAlign: 'center'}}>
                        Ahora podras crear tu cuenta, dar de alta tu tienda virtual en este market place de mujeres
                        empresarias unidas y vender sin complicaciones en internet
                    </Typography>
                </Grid>

                <Grid item container lg={3} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}/>


                <Grid item container lg={3} sm={5} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 8}}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontSize: 26,
                                fontWeight: 800,
                                textAlign: 'center',
                                lineHeight: 1.2
                            }}>Crea tu
                                contraseña </Typography>
                        </Grid>


                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 1}}>
                            <Typography sx={{fontSize: 18, fontWeight: 400, fontFamily: 'Lato', textAlign: 'center'}}>
                                Recuerda que este será el correo y contraseña con la que ingresaras siempre.
                            </Typography>
                        </Grid>


                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: 4, marginBottom: 2}}>
                            <Formulario_contrasena props={props}/>
                        </Grid>

                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-start'}}>
                            <Checkbox checked={aceptado} onChange={() => setAceptado(!aceptado)}/>
                        </Grid>

                        <Grid item container lg={10} sm={10} xs={10}
                              sx={{justifyContent: 'flex-start', marginTop: 1.1}}>
                            <Typography sx={{color: '#00000080', fontSize: 14}}>Acepto las politicas de tratamientos de
                                datos</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                            <Button
                                onClick={() => crearCuenta()}
                                startIcon={<TaskAlt/>} color={'primary'}
                                sx={{color: '#fff', px: 4, py: 1, fontWeight: 500, fontSize: 16}}>
                                crear contraseña e ingresar
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Registro