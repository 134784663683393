/**************************************************
 * Nombre:       Solicitud_Contrasena
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import {useEscucharAuth} from "../../Servicios/Auth/useEscucharAuth";
import {useEscucharUsuarioToCorreo} from "../../Servicios/BD/useEscucharUsuarioToCorreo";
import Ya_Registrada from "./Ya_Registrada";
import Registro from "./Registro";
import {useEffect} from "react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {funSalir} from "../../Servicios/Auth/funSalir";
import {useParams} from "react-router-dom";

const Solicitud_Contrasena = () => {
    const parms = useParams()
    const {email} = useEscucharAuth()
    const {usuario} = useEscucharUsuarioToCorreo({correo: email})


    useEffect(() => {
        console.log(usuario)

        if (usuario) {
            obtenerDoc('usuarios', usuario.id).then((dox) => {
                if (dox.res) {
                    if (dox.data.idEmpresaria !== parms.id) {
                        funSalir()
                    }

                }
            })
        }


    }, [usuario]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            {usuario ?

                <Ya_Registrada/>

                :

                <Registro/>
            }


        </Grid>
    )

}
export default Solicitud_Contrasena