import './App.css';
import {Grid} from "@mui/material";
import PaginaConstruccion from "./PaginaConstruccion/PaginaConstruccion";
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';


function App() {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <PaginaConstruccion/>


        </Grid>
    );
}

export default App;
