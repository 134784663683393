/**************************************************
 * Nombre:       Formulario_Potenciales
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountCircle, Apartment, Email, Flag, HomeWork, Mail, Person, PhoneAndroid} from "@mui/icons-material";
import {PAISES, REDES} from "../../Constantes";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";

const Formulario_Potenciales = ({...props}) => {

    return (
        <form noValidate>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Person} nombre={"Nombre"} dato={'nombre'} {...props}
                    requerido={'El nombre es requerido'}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoSelect Icono={Apartment} nombre={"Pais"} dato={'pais'}
                                   opciones={PAISES}
                                   requerido={'El pais es requerido'}
                                   {...props} />
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={HomeWork} nombre={"Ciudad"} dato={'ciudad'} {...props}
                                  requerido={'La ciudad es requerido'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Email} nombre={"Correo"} dato={'correo'} {...props}
                                  requerido={'El correo es requerido'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={PhoneAndroid} nombre={"Numero WhatsApp"} dato={'celular'} {...props}
                                  requerido={'El celular es requerido'}

                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Flag} nombre={"Sector economico"} dato={'sector'} {...props}
                                  requerido={'El celular es requerido'}
                    />
                </Grid>



            </Grid>
        </form>
    )

}
export default Formulario_Potenciales