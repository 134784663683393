/**************************************************
 * Nombre:       Registro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import {irUrl} from "../../Utilidades/irUrl";

const Ya_Registrada = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{pb: 8}}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={11} sm={8} xs={12} sx={{justifyContent: 'center', marginTop: 12}}>
                    <Typography sx={{
                        color: theme.palette.primary.main,
                        fontSize: 38,
                        fontWeight: 800,
                        textAlign: 'center',
                        lineHeight: 1.2
                    }}>Contraseña Creada</Typography>
                </Grid>

                <Grid item container lg={3} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}/>

                <Grid item container lg={6} sm={8} xs={12} sx={{justifyContent: 'center', marginTop: 1}}>
                    <Typography sx={{fontSize: 18, fontWeight: 400, fontFamily: 'Lato', textAlign: 'center'}}>
                        Dirigete al siguiente link e ingresa a tu tienda virtual con tu correo y contraseña
                    </Typography>
                </Grid>


                <Grid item container lg={3} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}/>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4, px: 4}}>
                    <Typography
                        onClick={() => irUrl('https://tienda.mujeresempresariasunidas.com')}
                        sx={{
                            color: theme.palette.primary.main,
                            fontSize: sCell ? 16 : 24,
                            fontWeight: 800,
                            textAlign: 'center',
                            lineHeight: 1.2,
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}>tienda.mujeresempresariasunidas.com</Typography>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default Ya_Registrada