export const adaptador_entidad_tienda = (ent) => {

    let cor = ent.correo.replaceAll('@', '_')
    let cormas = cor.replaceAll('.', '-')


    let obj = {
        id: cormas.toLowerCase(),
        correo: ent.correo,
        nombre: ent.nombre,
        tipo: 'Empresaria',
        idEmpresaria: ent.id,
        fecha: new Date().getTime(),
        credenciales: ['Pedidos', 'Tienda', 'Catalogo', 'Categorias', 'Pagos', 'Soporte']
    }

    return obj

}