/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo_redondo from "../../Recursos/logo_redondo_oscuro.png";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {
    enviarNotificacionMultilplesTokens
} from "../../Modulo_Notificaciones/Funciones/enviarNotificacionMultilplesTokens";
import logo from "../../Recursos/logo_oscuro.svg";
import Formulario_Potenciales from "../Formularios/Formulario_Potenciales";
import {LoadingButton} from "@mui/lab";
import {REDES} from "../../Constantes";
import {irUrl} from "../../Utilidades/irUrl";

const Pagina = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: {}})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo_redondo})


    const guardar = () => {


        obtenerEntidad().then((entidad) => {

            abrirCargador('Enviando Datos')
            let obj = entidad
            obj.fecha = new Date().getTime()
            obj.estado = 'Nueva'
            guardarDoc('potenciales', obj).then((dox) => {
                if (dox.res) {
                    limpiarEntidad()
                    notificar(entidad)
                    alert('Tus datos han sido enviados con exito, pronto nos comunicaremos contigo')
                } else {
                    alert('Ups, algo indesperado paso, intentalo nuevamente')
                }
                cerrarCargador()
            })
        })

    }

    const notificar = (entidad) => {

        obtenerDoc('tokens', 'adminTokens').then((dox) => {
            if (dox.res) {
                let arr = dox.data.tokens
                if (arr && arr.length > 0) {
                    enviarNotificacionMultilplesTokens({
                        arrToken: arr,
                        titulo: 'Nueva Empresaria Potencial',
                        mensaje: `${entidad.nombre} a deajado sus datos`
                    })
                }
            }
        })

    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{background: 'linear-gradient(90deg, #F43745 30%, #F43742 180%)', p: 2, minHeight: '100vh'}}
        >


            <Cargador/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                    maxWidth: '800px',
                    px: sCell ? 4 : 6,
                    py: sCell ? 6 : 6,
                    borderRadius: 10,
                    boxShadow: 12,
                    backgroundColor: '#fff'
                }}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <img src={logo} width={250} height={'auto'}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                    <Typography
                        sx={{
                            fontSize: sCell ? 24 : 32,
                            fontWeight: 700,
                            textAlign: 'center',
                            lineHeight: 1.2
                        }}>Preparandonos para el lanzamiento</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: sCell ? 2 : 0}}>
                    <Typography sx={{fontSize: 16, fontWeight: 300, textAlign: 'center',}}>Si deseas ser parte de este
                        marketplace por favor ingresa tus datos</Typography>
                </Grid>

                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                    <Formulario_Potenciales props={props}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                    <LoadingButton
                        onClick={() => guardar()}
                        variant={'contained'}
                        color={'primary'}
                        sx={{fontSize: 16, fontWeight: 600, color: '#fff'}}
                    >
                        ENVIAR INFORMACION
                    </LoadingButton>
                </Grid>

                <Grid item container lg={8} sm={8} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        {REDES.map((item, index) => {
                            return (
                                <Grid key={`red-${index}`} item container lg={2} sm={2} xs={2}
                                      sx={{justifyContent: 'center'}}>
                                    <ButtonBase
                                        onClick={() => irUrl(item.url)}
                                        sx={{borderRadius: 2}}>
                                        <item.icono sx={{width: 35, height: 35, fill: '#EC2B23'}}/>
                                    </ButtonBase>
                                </Grid>
                            )
                        })}

                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Pagina